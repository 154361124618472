import type { NfdRecord } from 'api/api-client'
import type { NfdTag, NfdTagColors } from 'components/ResultTag'

type GetTagsOptions = {
  premium?: boolean
}

export default function getTags(nfd: NfdRecord, options: GetTagsOptions = {}): NfdTag[] {
  const { premium = false } = options

  // if premium is true, only show tags that affect price
  // (used in mint search results)
  const premiumTags = {
    category: ['curated', 'premium'],
    metaTags: [
      '1_letters',
      '2_letters',
      '3_letters',
      '4_letters',
      '5_letters',
      '6_letters',
      '7_letters',
      '8_letters',
      '9_letters'
    ]
  }

  const getCategoryTag = () => {
    if (!nfd.category) return []

    if (premium && !premiumTags.category.includes(nfd.category as string)) {
      return []
    }

    return [
      {
        label: nfd.category as string,
        color: 'blue' as NfdTagColors,
        capitalize: true
      }
    ]
  }

  const getStateTag = () => {
    if (premium) {
      return []
    }

    if (!nfd.state) {
      throw new Error('NFD state is missing.')
    }

    return [
      {
        // convert 'forSale' to 'For Sale'
        label: nfd.state.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()),

        // green if available to buy
        color:
          nfd.state === 'forSale' || nfd.state === 'reserved'
            ? ('green' as NfdTagColors)
            : ('gray' as NfdTagColors)
      }
    ]
  }

  const getMetaTags = () => {
    let metaTags = nfd.metaTags || []

    if (premium) {
      metaTags = metaTags.filter((tag) => premiumTags.metaTags.includes(tag))
    }

    return metaTags
      .filter((tag) => tag.endsWith('_letters')) // only show letters tags (for now)
      .map((tag) => ({
        // format label
        label: tag === '1_letters' ? '1 letter' : tag.replace('_letters', ' letters'),

        // yellow if < 10 letters
        color: tag === '10+_letters' ? ('gray' as NfdTagColors) : ('yellow' as NfdTagColors)
      }))
  }

  return [...getCategoryTag(), ...getStateTag(), ...getMetaTags()]
}
