import { useMemo, useLayoutEffect, useRef, useState, useEffect, useCallback } from 'react'
import { getTextAvatarBgURL } from 'helpers/avatar'
import type { NfdRecord } from 'api/api-client'

interface TextAvatarInterface {
  className?: string
}

type TextAvatarProps = (
  | {
      nfd: NfdRecord
      name?: string
      imgSrc?: string
    }
  | {
      nfd?: never
      name: string
      imgSrc?: string
    }
) &
  TextAvatarInterface

const FULL_WIDTH = 360
const MARGIN = 40
const FONT_SIZE = 32

const TEXT_MAX_WIDTH = FULL_WIDTH - MARGIN * 2
const TEXT_X_POS = MARGIN
const TEXT_Y_POS = FULL_WIDTH - MARGIN - FONT_SIZE / 3

export default function TextAvatar({ nfd, name, imgSrc, className = '' }: TextAvatarProps) {
  const [showText, setShowText] = useState(false)
  const [domRect, setDomRect] = useState<DOMRect | null>(null)

  const scaleTextRef = useRef<SVGTextElement>(null)

  useLayoutEffect(() => {
    if (scaleTextRef.current !== null) {
      const bbox = scaleTextRef.current.getBBox()
      setDomRect(bbox)
    }
  }, [])

  useEffect(() => {
    if (domRect && domRect.width > TEXT_MAX_WIDTH) {
      const scale = TEXT_MAX_WIDTH / domRect.width
      scaleTextRef?.current?.setAttribute('font-size', `${FONT_SIZE * scale}px`)
    }

    setShowText(true)
  }, [domRect])

  const getBgImage = useCallback(() => {
    if (!imgSrc) return imgSrc

    if (imgSrc.includes('nfd-image-placeholder-bg_gray')) {
      return '/img/nfd-image-placeholder-bg_gray.jpg'
    }

    if (imgSrc.includes('nfd-image-placeholder-bg_gold')) {
      return '/img/nfd-image-placeholder-bg_gold.jpg'
    }

    if (imgSrc.includes('nfd-image-placeholder')) {
      return '/img/nfd-image-placeholder-bg.jpg'
    }

    return imgSrc
  }, [imgSrc])

  const xlinkHref = useMemo(() => {
    if (nfd) {
      return getBgImage() || getTextAvatarBgURL(nfd)
    }

    return getBgImage()
  }, [getBgImage, nfd])

  const nfdName = useMemo(() => {
    if (nfd) {
      return name || nfd.name
    }

    return name
  }, [name, nfd])

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox={`0 0 ${FULL_WIDTH} ${FULL_WIDTH}`}
      xmlSpace="preserve"
      className={className}
    >
      <image x="0" y="0" width={FULL_WIDTH} height={FULL_WIDTH} xlinkHref={xlinkHref} />
      <text
        ref={scaleTextRef}
        x={TEXT_X_POS}
        y={TEXT_Y_POS}
        fill="#ffffff"
        fontFamily="Inter avatar"
        fontSize={FONT_SIZE}
        fontWeight="600"
        textAnchor="start"
        dominantBaseline="middle"
        style={{ textShadow: '0 1px 1px rgba(0,0,0,0.25)' }}
        className={showText ? 'opacity-100' : 'opacity-0'}
      >
        {nfdName}
      </text>
    </svg>
  )
}
